import React from "react";

const StaticFooter = () => {
  return (
    <div className="bg-[#4E485D] pt-14 py-6 lg:py-12 flex justify-center text-white">
      <div className="flex flex-col md:flex-row items-center space-y-2">
        {/* <div className="flex items-center px-10">
          <img className="w-14 h-14 mr-4" src="/img/email-message-icon.png" alt="chat" />
          <div className="w-full max-w-sm sm:w-52 lg:w-80 text-sm lg:text-xl">
            หากพบปัญหาในการใช้งานหรือติดตั้ง กรุณาติดต่อเราทาง Messenger ใน Facebook
          </div>
        </div> */}
        <div className="flex justify-center">
          <img className="h-12" alt="logo" src="/img/fitforce2logo.png" />
        </div>
        <div className="w-10 v-5"></div>
        <div>
          <a
            href="/terms"
            target="_blank"
            className="cursor-pointer text-center"
            rel="noreferrer"
          >
            Terms of service
          </a>
        </div>
        <div className="w-10 v-5"></div>
        <div>
          <a
            href="/privacy-policy"
            target="_blank"
            className="cursor-pointer text-center"
            rel="noreferrer"
          >
            Privacy policy
          </a>
        </div>
        <div className="w-10 v-5"></div>
        <div className="flex flex-row justify-center space-x-3">
          <div>
            <a
              href="https://www.facebook.com/fitforce.th"
              target="_blank"
              className="cursor-pointer text-center"
              rel="noreferrer"
            >
              <img className="h-7" alt="fb-logo2" src={require("../img/fbIcon.png")}/>
            </a>
          </div>
          <div>
            <a
              href="https://www.tiktok.com/@fitforce2.th"
              target="_blank"
              className="cursor-pointer text-center"
              rel="noreferrer"
            >
              <img className="h-8" alt="fb-logo2" src={require("../img/TikTok.png")}/>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaticFooter;
