import React from "react";
import Favicon from "react-favicon";
import { Navigate, Route, Routes } from "react-router-dom";
import RedirectToURL from "./components/RedirectToURL";
import "./css/App.css";
import useAuth from "./hooks/useAuth";
import "./i18n";
import AdminLayout from "./layouts/AdminLayout";
import AdminActivationCode from "./pages/admin/AdminActivationCode";
import AdminAdsManager from "./pages/admin/AdminAdsManager";
import AdminArticleDetail from "./pages/admin/AdminArticleDetail";
import AdminArticleList from "./pages/admin/AdminArticleList";
import AdminQuizManager from "./pages/admin/AdminQuizManager";
import AdminCBTRegistration from "./pages/admin/AdminCBTRegistration";
import AdminFeedback from "./pages/admin/AdminFeedback";
import AdminLogin from "./pages/admin/AdminLogin";
import AdminOverview from "./pages/admin/AdminOverview";
import AdminRetention from "./pages/admin/AdminRetention";
import AdsPerformance from "./pages/admin/AdsPerformance";
import GameExp from "./pages/admin/GameExp";
import Overview from "./pages/admin/Overview";
import Retention from "./pages/admin/Retention";
import SaleData from "./pages/admin/SaleData";
import UserByCurrency from "./pages/admin/UserByCurrency";
import UserFlow from "./pages/admin/UserFlow";
import BlogPage from "./pages/front/Blog";
import CBTInstallAndroid from "./pages/front/CBTInstallAndroid";
import CBTInstalliOS from "./pages/front/CBTInstalliOS";
import FAQPage from "./pages/front/FAQ";
import { default as Fitforce } from "./pages/front/Fitforce";
import Fitforce2CBT from "./pages/front/Fitforce2CBT";
import FitforceGetApp from "./pages/front/FitforceGetApp";
import FitforceHowToInstall from "./pages/front/FitforceHowToInstall";
import FitforceHowToInstallV2 from "./pages/front/FitforceHowToInstallV2";
import FitforceInstallPC from "./pages/front/FitforceInstallPC";
import FitforceTroubleshoot from "./pages/front/FitforceTroubleshoot";
import Fitnow from "./pages/front/Fitnow";
import FitTroopFood from "./pages/front/FitTroopFood";
import FitTroopToon from "./pages/front/FitTroopToon";
import Policy from "./pages/front/Policy";
import RedeemCode from "./pages/front/RedeemCode";
import Runster from "./pages/front/Runster";
import RunsterAboutUs from "./pages/front/RunsterAboutUs";
import Terms from "./pages/front/Terms";
import Quiz from "./pages/front/Quiz";

const Private = (Component) => {
  const { isLoggedIn, loadAuthHeader } = useAuth();
  loadAuthHeader();
  return isLoggedIn() ? <Component /> : <Navigate to="/login" />;
};

const App = () => {
  return (
    <>
      <Favicon url={require("./img/Fitforce2/ff2-pfp-trans-sm.png")} />
      <AdminLayout>
        <Routes>
          <Route path="/fitforce" element={<Fitforce />} />
          <Route path="/fitforce/get-app" element={<FitforceGetApp />} />
          <Route path="/fitforce/install-pc" element={<FitforceInstallPC />} />
          <Route path="/fitforce/troubleshoot-window" element={<FitforceTroubleshoot />} />
          <Route path="/fitforce/how-to-install" element={<FitforceHowToInstall />} />
          <Route path="/fitforce/how-to-install-v2" element={<FitforceHowToInstallV2 />} />
          <Route path="/runster" element={<Runster />} />
          <Route path="/runster/about-us" element={<RunsterAboutUs />} />
          <Route path="/fitnow" element={<Fitnow />} />
          <Route path="/fitranger" element={<FitTroopToon />} />
          <Route path="/fit-ranger" element={<FitTroopFood />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy-policy" element={<Policy />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/redeem-code" element={<RedeemCode />} />
          <Route path="/bt-install-android" element={<CBTInstallAndroid />} />
          <Route path="/bt-install-ios" element={<CBTInstalliOS />} />
          <Route path="/login" element={<AdminLogin />} />
          <Route path="/admin/cbt-registration" element={Private(AdminCBTRegistration)} />
          <Route path="/admin/overview" element={Private(AdminOverview)} />
          <Route path="/admin/retention" element={Private(AdminRetention)} />
          <Route path="/admin/activation-code" element={Private(AdminActivationCode)} />
          <Route path="/admin/cbt-feedbacks" element={Private(AdminFeedback)} />
          <Route path="/admin/questions" element={Private(AdminQuizManager)} />
          <Route path="/admin/ads-manager" element={Private(AdminAdsManager)} />
          <Route path="/admin/articles" element={Private(AdminArticleList)} />
          <Route path="/admin/articles/create" exact element={Private(AdminArticleDetail)} />
          <Route path="/admin/articles/:id" element={Private(AdminArticleDetail)} />
          <Route path="/admin/v1/overview" element={Private(Overview)} />
          <Route path="/admin/v1/ads-performance" element={Private(AdsPerformance)} />
          <Route path="/admin/v1/game-exp" element={Private(GameExp)} />
          <Route path="/admin/v1/sale-data" element={Private(SaleData)} />
          <Route path="/admin/v1/user-currency" element={Private(UserByCurrency)} />
          <Route path="/admin/v1/retention" element={Private(Retention)} />
          <Route path="/admin/v1/user-flow" element={Private(UserFlow)} />
          <Route path="/admin" element={<Navigate to="/admin/overview" />} />
          <Route path="/blogs/:slug" element={<BlogPage />} />
          <Route path="/r/:key" element={<RedirectToURL />} />
          <Route path="/" element={<Fitforce2CBT />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AdminLayout>
    </>
  );
};

export default App;
