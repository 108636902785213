import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { frontApi } from "../../api";
import TextInput from "../../components/forms/TextInput";
import TextInputWithButton from "../../components/forms/TextInputWithButton";
import StaticLayout from "../../layouts/StaticLayout";
import { Label } from "recharts";


const RedeemCode = () => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = React.useState(false);

  const handleRedeemCode = () => {
    formik.handleSubmit();
  };

  const formik = useFormik({
    initialValues: {
      uid: "",
      redeemCode: "",
    },
    validationSchema: Yup.object({
      uid: Yup.string().required("UID is Required"),
    }),
    onSubmit: (values) => {
      frontApi
        .redeemCode(values.uid, values.redeemCode)
        .then(() => {
          setShowModal(true)
          formik.setValues({redeemCode: "", uid: values.uid});
        })
        .catch((e) => {
          if (e?.response?.status === 400) {
            if(e?.response?.data?.ErrorMessage === "UID not found"){
              formik.setErrors({ uid: e?.response?.data?.ErrorMessage });
            }
            else {
              formik.setErrors({ redeemCode: e?.response?.data?.ErrorMessage });
            }
          } else {
            formik.setErrors({ redeemCode: "Something went wrong" });
          }
        })
        .finally(() => setLoading(false));
    },
  });

  return (
    <StaticLayout title="Redeem Code">
      <div className="px-8 md:py-8 bg-white bg-opacity-85 rounded-xl">
        <div className="max-w-4xl mx-auto">
          <div className="flex flex-col md:flex-row ">
            <div className="flex-[3] lg:pl-8 lg:pr-20 pt-5">
              <label for="uid" class="block mb-2 text-sm font-bold text-gray-900">UID: </label>
              <TextInput
                placeholder="Enter your UID"
                name="uid"
                onChange={formik.handleChange}
                value={formik.values.uid}
                error={formik.errors.uid}
              />
              <label for="first_name" class="block mb-2 text-sm font-bold text-gray-900">Redeem code: </label>
              <TextInput
                placeholder="Enter code"
                name="redeemCode"
                onChange={formik.handleChange}
                value={formik.values.redeemCode}
                error={formik.errors.redeemCode}
              />
              <button
                onClick={handleRedeemCode}
                className="bg-indigo-600 w-full text-white rounded text-center py-3 mt-4 text-xl cursor-pointer disabled:bg-slate-400 disabled:cursor-not-allowed"
                disabled={loading || formik.values.uid === "" || formik.values.redeemCode === ""}
              >
                Redeem
              </button>
              <div>
                <br></br>
                <p class="text-xl text-blue-600 font-semibold">Cannot find your UID?</p>
                <div class="pt-2"></div>
                <p class="text-gray-600">You can find the UID in the Fitforce2 app by going to Settings > Account and pressing the copy icon</p>
                <br></br>
              </div>
            </div>
            <div className="flex-[3] flex justify-center items-center pb-4">
              <img
                src={require("../../img/Fitforce2/redeem-ex.jpg")}
                alt="redeem-ex"
                className="max-h-[70vh] max-w-[50vw]"
              />
            </div>
          </div>
        </div>
      </div>
      <br></br>
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none p-3"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6">
                <div className="relative flex-auto content-start p-1">
                  <center>
                    <img alt="success" className="w-24 my-4" src={require("../../img/SuccessCheck.png")}/>
                    </center>
                    <p className="text-blueGray-500 text-lg leading-relaxed font-semibold text-center">
                      Redeem success
                    </p>
                    <p className="text-blueGray-200 text-sm leading-relaxed text-center">
                    Open the app to view your inventory.
                    </p>
                </div>
                <div className="flex items-center justify-center pb-6 rounded-b mt-4">
                  <button
                    className="bg-blue-600 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 w-40"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </StaticLayout>
  );
};

export default RedeemCode;
