import React, { Component } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import Fitforce from "./Fitforce";

class FitforceGetApp extends Component {
  componentDidMount() {
    if (isIOS) {
      window.location = "https://itunes.apple.com/us/app/fitforce/id1402662894?mt=8";
    } else if (isAndroid) {
      window.location = "https://play.google.com/store/apps/details?id=com.runster&hl=en";
    }
  }

  render() {
    return <Fitforce />;
  }
}

export default FitforceGetApp;
