import React, { useEffect } from "react";
import StaticFooter from "../components/StaticFooter";
import "../css/font-kanit.css";

const StaticLayout = ({
  title = "No Title",
  showTitle = true,
  titleCenter = false,
  card = false,
  children,
}) => {
  useEffect(() => {
    document.title = "Fitforce 2 - " + title;
  }, [title]);

  return (
    <div className="font-kanit bg-gradient-to-b from-indigo-600 to-gray-200 min-h-screen w-full flex flex-col justify-between">
      <div className="p-6 lg:p-20 pt-10 lg:pt-12 flex flex-col items-center">
        <div className="flex justify-center">
          <img className="h-12" alt="logo" src="/img/fitforce2logo.png" />
        </div>
        <div className={"flex flex-col w-full" + (card ? " max-w-4xl mt-4" : " max-w-6xl")}>
          {showTitle && (
            <div>
              {titleCenter ? (
                <div className="text-white text-center text-3xl my-4 lg:my-8">{title}</div>
              ) : (
                <p className="text-white text-lg mb-4 mt-8 lg:mt-16">{title}</p>
              )}
            </div>
          )}
          {card ? (
            <div className={"opacity-90 min-h-[50vh] rounded-xl p-8 bg-white"}>{children}</div>
          ) : (
            children
          )}
        </div>
      </div>
      <StaticFooter />
    </div>
  );
};

export default StaticLayout;
